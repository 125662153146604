import fiveam from '../static/images/kleinekompressoren/cards/fiveam.jpg';
import moncofa from '../static/images/kleinekompressoren/cards/moncofa.jpg';
import shutterstock from '../static/images/kleinekompressoren/cards/shutterstock.jpg';
import { MultiCardSliderCardInterface } from '../interfaces/multiCardSliderCard';

export const smallCompressorsCards: Array<MultiCardSliderCardInterface> = [
    {
        title: 'FIVE:AM, AUSTRALIEN',
        image: fiveam,
        subTitle: '100% BIO DANK BOGE',
        description:
            'Der australische Joghurt-Hersteller five:am verwendet für seine Produkte nur biologische Zutaten – und vertraut bei der sensiblen Herstellung auf absolut ölfreie Luft von BOGE.',
        linkText: 'Mehr erfahren',
        link: '/de/referenzen/five-am',
    },
    {
        title: 'IDAM Moncofa',
        image: moncofa,
        subTitle: 'AUS DEM MEER INS GLAS',
        description:
            'Im heißen Spanien bereitet IDAM Moncofa Meerwasser auf. In der Entsalzungsanlage sorgt BOGE Druckluft dafür, dass für die Menschen und die Landwirtschaft genug Wasser da ist.',
        linkText: 'Mehr erfahren',
        link: '/de/referenzen/moncofa',
    },
    {
        title: 'Reimann & Rößle CNC-Zerspanung',
        image: shutterstock,
        subTitle: 'EFFIZIENZ GEHT EFFIZIENTER',
        description: 'Wie Reimann & Rößle die Effizienz einer existierenden C-Baureihen Anlage weiter steigern konnte.',
        linkText: 'Mehr erfahren',
        link: '/de/referenzen/reimann-roessle',
    },
];
