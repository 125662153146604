import React from 'react';
import { Card, Img, Content, Title, TitleSection, CheckIcon, Text, TextDivider } from './SmallCompressorsStyle';

interface PropTypes {
    img: any;
    title: String;
    description?: Array<String>;
}

const SmallCompressorsItem = ({ img, title, description }: PropTypes) => (
    <Card>
        <Img src={img} alt="CompressorImg"></Img>
        <Content>
            <TitleSection>
                <CheckIcon />
                <Title>{title}</Title>
            </TitleSection>
            <Text>
                {description.map((text: String, index: number) =>
                    index % 2 === 0 ? (
                        <TextDivider key={index}>{text}</TextDivider>
                    ) : (
                        <TextDivider key={index}>
                            <strong>{text}</strong>
                        </TextDivider>
                    )
                )}
            </Text>
        </Content>
    </Card>
);

export default SmallCompressorsItem;
