import React, { useEffect, useRef } from 'react';
import Layout from '../components/Layout/Layout';
import { graphql } from 'gatsby';
import PrinterWrapper from '../components/PrintWrapper/PrintWrapper';
import SmallCompressorsTable from '../components/KleineKompressoren/SmallCompressorsTable/SmallCompressorsTable';
import SmallCompressorsUsefulInformation from '../components/KleineKompressoren/SmallCompressorsUsefulInformation/SmallCompressorsUsefulInformation';
import SmallCompressorsAdvantagesDescription from '../components/KleineKompressoren/SmallCompressorsDescription/SmallCompressorsAdvantagesDescription';
import SmallCompressorsContact from '../components/KleineKompressoren/SmallCompressorsContact/SmallCompressorsContact';
import SmallCompressorsThumbnails from '../components/KleineKompressoren/SmallCompressorsThumbnails/SmallCompressorsThumbnails';
import SmallCompressorsSection from '../components/KleineKompressoren/SmallCompressorsCards/SmallCompressorsSection';
import SmallCompressorsTopDescription from '../components/KleineKompressoren/SmallCompressorsTopDescription/SmallCompressorsTopDescription';
import SmallCompressorsBestseller from '../components/KleineKompressoren/SmallCompressorsBestseller/SmallCompressorsBestseller';
import PinnedCard from '../components/KleineKompressoren/PinnedCard/PinnedCard';
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import Header from '../components/HeaderDynamicPages/Header';
import { smallCompressorsCards } from '../constants/smallCompressorsCards';
import MultiCardSlider from '../components/Sliders/MultiCardSlider/MultiCardSlider';
import { ContainerCommon, CommonH3, Center } from '../styles/Common';
import Alternate from '../components/Seo/Alternate';
import { dataToBreadcrumbsFromNav } from '../utils/dataToBreadCrumbsFromNav';
import smallCompressorsTranslations from '../JSON/StaticSitesTranslations/smallCompressorsTranslations.json';
import { getSiteName } from '../utils/getSiteName';
import { DE, GLOBAL } from '../constants/languageCode';

const KleineKompressoren = () => {
    const { language, changeLanguage } = useI18next();
    const SmallCompressorsContactRef = useRef(null);
    const scrollRef = useRef(null);
    const { t } = useTranslation();
    const languagesWithUrls = [];
    const { category, categoryItem, item } = dataToBreadcrumbsFromNav(language, 'kleine-kompressoren-c-baureihe');
    process.env.SMALL_COMPRESSORS_LANGUAGES.split(',').forEach((language: string) =>
        languagesWithUrls.push({ language: language, url: 'kleine-kompressoren-c-baureihe' })
    );

    useEffect(() => {
        if (language === GLOBAL) {
            changeLanguage(DE);
        }
    }, []);

    const scrollToContact = () =>
        SmallCompressorsContactRef?.current?.scrollIntoView({ block: 'center', behavior: 'smooth' });

    const scrollHandler = () => {
        scrollRef?.current?.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <Layout
            title={`${getSiteName(language)} | ${t('smallScrewCompressors')}`}
            languageVersionsUrl={smallCompressorsTranslations}
        >
            <Alternate languages={languagesWithUrls} />
            <Header category={category} categoryItem={categoryItem} item={item ?? 'Die BOGE C-Baureihe'} />
            <PinnedCard onClick={scrollHandler} />
            <PrinterWrapper>
                <SmallCompressorsTopDescription />
                <SmallCompressorsThumbnails />
                <SmallCompressorsSection />
                <SmallCompressorsAdvantagesDescription scrollToContact={scrollToContact} scrollRef={scrollRef} />
                {language === DE && <SmallCompressorsTable />}
                <SmallCompressorsBestseller />
                <ContainerCommon>
                    <CommonH3>{t('referenzenCBaureihe')}</CommonH3>
                    <Center>
                        <MultiCardSlider cards={smallCompressorsCards} />
                    </Center>
                </ContainerCommon>
                <div ref={SmallCompressorsContactRef}>
                    <SmallCompressorsContact />
                </div>
                <SmallCompressorsUsefulInformation />
            </PrinterWrapper>
        </Layout>
    );
};

export default KleineKompressoren;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
