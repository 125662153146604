import styled from 'styled-components';
import { blueTable, white, blue, containerWidth } from '../../../styles/Variables';
import { Link } from 'gatsby';
import { deviceMax } from '../../../styles/MediaQuery';
import { ContainerColumnStart } from '../../../styles/Common';

export const PageWrapper = styled(ContainerColumnStart)`
    margin: 0 auto;

    @media ${deviceMax.tabletM} {
        padding: 0 15px;
        width: calc(100% - 30px);
    }
`;

export const TableWrapper = styled.div`
    overflow-x: auto;
    padding: 0 0 1em 0;
`;

export const Table = styled.table`
    border-collapse: collapse;
    width: ${containerWidth};
    margin: auto;

    * {
        padding-left: 1.5em;
        padding-right: 1em;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 0.875rem;
    }
`;

export const TableHead = styled.thead`
    background-color: ${blueTable};
    border-right: 1px solid ${white};
    border-collapse: collapse;
    text-align: left;

    tr {
        vertical-align: top;
    }
`;

export const TableRowBody = styled.tr`
    &:nth-child(odd) {
        background-color: ${white};
    }

    * {
        padding-block: 0.7rem;
    }
`;

export const TableHeading = styled.th`
    border-right: 1px solid ${white};
    padding-top: 0.6rem;
    line-height: 20px;
`;

export const TableHeadingGroup = styled.th`
    border-right: 1px solid ${white};
    display: flex;
    flex-direction: column;
    padding-top: 0.6rem;
    padding-bottom: 0.2rem;
    line-height: 20px;

    span {
        padding: 0;
    }
`;

export const TableSubHeading = styled.th`
    border-right: 1px solid ${white};
    font-weight: 400;
    padding-top: 0.9rem;
    padding-bottom: 0.6rem;
`;

export const TableData = styled.td`
    border-right: 1px solid ${white};
`;

export const Description = styled.div`
    font-family: Arial, Helvetica;
    font-size: 0.875em;
    margin-top: -1px;
`;

export const TableLink = styled(Link)`
    font-weight: 400;
    font-family: Arial, Helvetica;
    font-size: 0.875em;
    line-height: 1.4em;
    text-decoration: none;
    color: ${blue};
    cursor: pointer;
`;

export const Sup = styled.sup`
    padding: 0;
    font-size: 0.7rem;
`;

export const Container = styled.div`
    margin-top: 1.2em;
`;
