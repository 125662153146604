import React from 'react';
import image from '../../../static/images/kleinekompressoren/boge-bafa-stoerer.png';
import { Image, Wrapper } from './PinnedCardStyles';

const PinnedCard = ({ onClick }) => (
    <Wrapper>
        <button onClick={onClick}>
            <Image src={image}></Image>
        </button>
    </Wrapper>
);

export default PinnedCard;
