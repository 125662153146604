import styled from 'styled-components';
import {
    ContainerColumnStart,
    ContainerColumnStartWithoutWidth,
    LinkButton,
    SectionHeadline,
} from '../../../styles/Common';
import { deviceMax } from '../../../styles/MediaQuery';
import { blue, greenDarker, greenLighter, white } from '../../../styles/Variables';

export const ContactContainer = styled(ContainerColumnStart)`
    margin: 2rem auto;

    @media ${deviceMax.tabletM} {
        padding: 0 15px;
        width: calc(100% - 30px);
    }
`;

export const ContactWrapper = styled.div`
    width: 100%;
    background: ${blue};
    display: grid;
    grid-template-columns: 35% 65%;

    @media ${deviceMax.mobileXl} {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto;
    }
`;

export const ContentWrapper = styled(ContainerColumnStartWithoutWidth)`
    padding: 20px 15px;
`;

export const ContentTitle = styled(SectionHeadline)`
    color: ${white};
`;

export const ContactField = styled.a`
    display: flex;
    align-items: center;
    text-decoration: none;
    color: ${white};
    font-size: 0.875rem;
`;

export const ContactIcon = styled.span`
    padding-right: 0.75rem;

    * {
        font-size: 1.25rem;
        fill: ${white};
    }
`;

export const RedirectionButton = styled(LinkButton)`
    background: linear-gradient(${greenLighter}, ${greenDarker});
    color: ${white};
    margin: 1rem 0;
    border: none;
`;

export const NavigationDiv = styled.div`
    position: relative;
    top: -150px;
`;
