import React from 'react';
import { GrayBackground, SectionTitle, ContainerColumn } from '../../../styles/Common';
import {
    Table,
    TableHead,
    TableRowBody,
    TableHeading,
    TableHeadingGroup,
    TableData,
    TableWrapper,
    TableSubHeading,
    Description,
    TableLink,
    PageWrapper,
    Sup,
    Container,
} from './SmallCompressorsTableStyle';
import { tableData, tableHeadings, tableSubHeadings } from './TableData';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const SmallCompressorsTable = () => {
    const { t } = useTranslation();

    return (
        <GrayBackground>
            <PageWrapper>
                <Container>
                    <SectionTitle>{t('smallCompressorsTableTitle')}</SectionTitle>
                </Container>
            </PageWrapper>

            <TableWrapper>
                <Table>
                    <TableHead>
                        <tr>
                            {tableHeadings.map((item: any, index: number) =>
                                Array.isArray(item) ? (
                                    item.length > 0 && (
                                        <TableHeadingGroup key={index}>
                                            {item?.map((text: string) => (
                                                <span key={text}>{text}</span>
                                            ))}
                                        </TableHeadingGroup>
                                    )
                                ) : (
                                    <TableHeading key={index}>{item}</TableHeading>
                                )
                            )}
                        </tr>
                        <tr>
                            {tableSubHeadings.map((item: string, index) =>
                                item === 'm3' ? (
                                    <TableSubHeading key={index}>
                                        m<Sup>3</Sup>/min
                                    </TableSubHeading>
                                ) : (
                                    <TableSubHeading key={index}>{item}</TableSubHeading>
                                )
                            )}
                        </tr>
                    </TableHead>
                    <tbody>
                        {tableData.map((item) => (
                            <TableRowBody key={item.typ}>
                                <TableData>{item.typ}</TableData>
                                <TableData>{item.bar}</TableData>
                                <TableData>{item.m3min}</TableData>
                                <TableData>{item.kW}</TableData>
                                <TableData>{item.mm}</TableData>
                                <TableData>{item.dB}</TableData>
                            </TableRowBody>
                        ))}
                    </tbody>
                </Table>
            </TableWrapper>
            <PageWrapper>
                <ContainerColumn>
                    <TableWrapper>
                        <Description>{t('smallCompressorsTableDesc1')}</Description>
                        <Description>{t('smallCompressorsTableDesc2')}</Description>
                        <Description>{t('smallCompressorsTableDesc3')}</Description>
                    </TableWrapper>
                    <TableLink to="/de/produkte?title=c-baureihe">Produktsortiment C-Baureihe</TableLink>
                </ContainerColumn>
            </PageWrapper>
        </GrayBackground>
    );
};

export default SmallCompressorsTable;
