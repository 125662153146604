import React from 'react';
import { SectionTitle } from '../../../styles/Common';
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import {
    ContactContainer,
    ContactField,
    ContactIcon,
    ContactWrapper,
    ContentTitle,
    ContentWrapper,
    RedirectionButton,
    NavigationDiv,
} from './SmallCompressorsContactStyle';
import { IoIosMail } from 'react-icons/io';
import { ImPhone } from 'react-icons/im';

const SmallCompressorsContact = () => {
    const { t } = useTranslation();

    const contactDetails = {
        email: 'salesteam@boge.de',
        tel: '+49 05206 601 - 160',
    };

    return (
        <ContactContainer>
            <NavigationDiv id="SmallCompressorsContact" />
            <SectionTitle>{t('smallCompressorsContactSectionTitle')}</SectionTitle>
            <ContactWrapper>
                <StaticImage
                    src="../../../static/images/kleinekompressoren/boge-kontakt-salesteam-original.jpg"
                    alt="sales team image"
                />
                <ContentWrapper>
                    <ContentTitle>{t('smallCompressorsContactSubtitle')}</ContentTitle>
                    <ContentTitle>{t('smallCompressorsContactSubtitle2')}</ContentTitle>
                    <ContactField href={`mailto:${contactDetails.email}`}>
                        <ContactIcon>
                            <IoIosMail />
                        </ContactIcon>
                        {contactDetails.email}
                    </ContactField>
                    <ContactField href={`tel:${contactDetails.tel}`}>
                        <ContactIcon>
                            <ImPhone />
                        </ContactIcon>
                        {contactDetails.tel}
                    </ContactField>
                    {/* TODO: Add link to this button, when site will be exist. */}
                    <RedirectionButton to="#">{t('smallCompressorsContactButton')}</RedirectionButton>
                </ContentWrapper>
            </ContactWrapper>
        </ContactContainer>
    );
};

export default SmallCompressorsContact;
