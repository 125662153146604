import styled from 'styled-components';
import { blue, white, greyLight, limaBackground, citronBackground, containerWidth } from '../../../styles/Variables';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { deviceMax } from '../../../styles/MediaQuery';
import { ContainerColumnStart } from '../../../styles/Common';
import { Link } from 'gatsby';

export const PageWrapper = styled(ContainerColumnStart)`
    margin: 2rem auto;

    @media ${deviceMax.tabletM} {
        padding: 0 15px;
        width: calc(100% - 30px);
    }
`;

export const Title = styled.h2`
    color: ${blue};
    font-size: 1.5em;
    margin: 0;
`;

export const SmallTitle = styled.h3`
    margin: 0.8em 0 0.5em 0;
`;

export const Image = styled.img`
    max-width: 375px;

    @media ${deviceMax.tabletM} {
        margin-top: -2em;
        object-fit: contain;
        width: auto;
        max-height: 260px;
    }

    @media ${deviceMax.mobileL} {
        max-width: 250px;
    }
`;

export const ComponentWrapper = styled.div`
    height: 220px;
    background: ${greyLight};
    margin: 2em 0 2em 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;

    @media ${deviceMax.tabletM} {
        flex-direction: column;
        height: auto;
    }
`;

export const Paragraph = styled.p`
    font-family: Arial, Helvetica;
    font-size: 0.875em;
    line-height: 1.5em;
    margin: 1.25em 1.25em 0 1.25em;
    font-weight: 400;
`;

export const ParagraphWrapper = styled.div`
    height: auto;
    display: flex;
    flex-flow: row wrap;
    align-content: center;
    background-color: ${greyLight};
`;

export const LinkWrapper = styled(Link)`
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    text-decoration: none;
`;

export const WidthWrapper = styled.div`
    max-width: ${containerWidth};
    box-sizing: border-box;
`;

export const Button = styled.button`
    width: 2.875em;
    height: 2.875em;
    margin: 1.25em 1.25em 1.25em 1.25em;
    background: linear-gradient(${limaBackground}, ${citronBackground});
    border: 0;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-content: center;
    font-family: Arial, Helvetica, sans-serif;
`;

export const Arrow = styled(AiOutlineArrowRight)`
    fill: ${white};
    font-size: 1.5em;
`;

export const LinkText = styled.div`
    color: ${limaBackground};
    font-family: Arial, Helvetica;
    font-size: 0.875em;
    cursor: pointer;
`;
