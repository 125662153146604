import React, { ReactNode } from 'react';
import { SectionTitle, ContainerColumnWithoutWidth, GrayBackground } from '../../../styles/Common';
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next';
import { Paragraph, Bold, Container, BestsellerLink, LinkWrapper } from './SmallCompressorsBestsellerStyle';
import { getSmallCompressorLinkInCurrentLanguage } from '../../../utils/getSmallComressorLinkInCurrentLanguage';
import { KleineKompressorenLinkInterface } from '../../../interfaces/kleineKompressorenLinks';

const SmallCompressorsBestseller = () => {
    const { t } = useTranslation();
    const { language } = useI18next();
    const link: Array<KleineKompressorenLinkInterface> = getSmallCompressorLinkInCurrentLanguage(language);

    const displayLinks = (): ReactNode =>
        link.map((item: KleineKompressorenLinkInterface, id) => (
            <LinkWrapper key={id}>
                <Bold>{item.title}</Bold>
                <BestsellerLink to={item.link}>{item.name}</BestsellerLink>
                {item.link2 && <BestsellerLink to={item.link2}>{item.name2}</BestsellerLink>}
            </LinkWrapper>
        ));

    return (
        <GrayBackground>
            <ContainerColumnWithoutWidth>
                <Container>
                    <SectionTitle>{t('smallCompressorsBestsellerTitle')}</SectionTitle>
                    <Paragraph>{t('smallCompressorsBestsellerParagraph')}</Paragraph>
                    {displayLinks()}
                </Container>
            </ContainerColumnWithoutWidth>
        </GrayBackground>
    );
};

export default SmallCompressorsBestseller;
