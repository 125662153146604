import React, { ReactNode } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import {
    ImagesWrapper,
    ImageWrapper,
    Paragraph,
    Description,
    PageWrapper,
    Bold,
    Img,
} from './SmallCompressorsThumbnailsStyle';
import Image1 from '../../../static/images/kleinekompressoren/kompressorsteuerung_boge.png';
import Image2 from '../../../static/images/kleinekompressoren/intelligentes-design_boge-industriekompressoren.png';
import Image3 from '../../../static/images/kleinekompressoren/extrem-leise-und-vibrationsarm_boge-kompressoren.png';
import Image4 from '../../../static/images/kleinekompressoren/kompakte-kleine-boge-kompressoren.png';
import Image5 from '../../../static/images/kleinekompressoren/kaeltetrockner-fuer-trockene-druckluft.png';
import { GrayBackground } from '../../../styles/Common';
import { useState } from 'react';
import GalleryView from '../../GalleryView/GalleryView';
import { GalleryViewImages } from '../../../interfaces/GalleryViewImages';

const SmallCompressorsThumbnails = () => {
    const [isGalleryVisible, setIsGalleryVisible] = useState(false);
    const [currentImage, setCurrentImage] = useState();

    const isGalleryVisibleHandler = (): void => setIsGalleryVisible((isGalleryVisible) => !isGalleryVisible);

    const currentImageHandler = (img: any): void => setCurrentImage(img);

    const { t } = useTranslation();

    const images: Array<GalleryViewImages> = [
        {
            id: 0,
            img: Image1,
            alt: t('steurung'),
            desc: t('steurungDesc'),
        },
        {
            id: 1,
            img: Image2,
            alt: t('bestensIntegiert'),
            desc: t('bestensIntegiertDesc'),
        },
        {
            id: 2,
            img: Image3,
            alt: t('extremLeise'),
            desc: t('extremLeiseDesc'),
        },
        {
            id: 3,
            img: Image4,
            alt: t('vorbidlichVereint'),
            desc: t('vorbidlichVereintDesc'),
        },
        {
            id: 4,
            img: Image5,
            alt: t('kaltetrockner'),
            desc: t('kaltetrocknerDesc'),
        },
    ];

    const displayImages = (): ReactNode =>
        images.map((img: GalleryViewImages, index: number) => (
            <ImageWrapper key={img.alt ?? index}>
                <Img
                    src={img.img}
                    alt={img.alt}
                    onClick={() => {
                        isGalleryVisibleHandler();
                        currentImageHandler(img.id);
                    }}
                />
                <Description>
                    <Bold>{img.alt}</Bold>
                    {img.desc}
                </Description>
            </ImageWrapper>
        ));

    return (
        <GrayBackground>
            {isGalleryVisible && (
                <GalleryView closeView={isGalleryVisibleHandler} imageId={currentImage} images={images} />
            )}
            <PageWrapper>
                <ImagesWrapper>{displayImages()}</ImagesWrapper>
                <Paragraph>{t('smallCompressorsThumbnailsParagraph')}</Paragraph>
            </PageWrapper>
        </GrayBackground>
    );
};

export default SmallCompressorsThumbnails;
