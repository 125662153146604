import React from 'react';
import {
    LinkText,
    Image,
    Paragraph,
    PageWrapper,
    WidthWrapper,
    LinkWrapper,
    Button,
    ParagraphWrapper,
    ComponentWrapper,
    Arrow,
} from './SmallCompressorsUsefulInformationStyle';
import { SectionTitle, SectionHeadline } from '../../../styles/Common';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import SchraubenkompressorImage from '../../../static/images/kleinekompressoren/boge-schraubenkompressor-_c-22-2_produkt.png';

const SmallCompressorsUsefulInformation = () => {
    const { t } = useTranslation();

    return (
        <PageWrapper>
            <WidthWrapper>
                <SectionHeadline>{t('scUsefulInfSmallTitle')}</SectionHeadline>
                <SectionTitle>{t('scUsefulInfTitle')}</SectionTitle>
                <ComponentWrapper>
                    <Image src={SchraubenkompressorImage} alt="BOGE Schraubenkompressor" />
                    <ParagraphWrapper>
                        <Paragraph>{t('scUsefulInfParagraph')}</Paragraph>
                        <LinkWrapper to="/de/produkte?title=c-">
                            <Button>
                                <Arrow />
                            </Button>
                            <LinkText>{t('scUsefulInfButtonText')}</LinkText>
                        </LinkWrapper>
                    </ParagraphWrapper>
                </ComponentWrapper>
            </WidthWrapper>
        </PageWrapper>
    );
};

export default SmallCompressorsUsefulInformation;
