import styled from 'styled-components';
import { containerWidth, greyBorder } from '../../../styles/Variables';
import { deviceMax } from '../../../styles/MediaQuery';
import { ContainerColumnStart } from '../../../styles/Common';
import { white } from '../../../styles/Variables';

export const PageWrapper = styled(ContainerColumnStart)`
    margin: 0 auto;

    @media ${deviceMax.tabletM} {
        padding: 0 15px;
        width: calc(100% - 30px);
    }
`;

export const Description = styled.div`
    display: none;
    margin-top: 0.2em;
    width: 300px;
    padding: 0.2em;
    background: ${white};
    font-family: Arial, Helvetica;
    font-size: 0.8em;

    @media ${deviceMax.tabletXl} {
        width: 250px;
        margin-left: -57px;
    }
`;

export const ImageWrapper = styled.div`
    height: 144px;
    width: 144px;
    border: 1px solid ${greyBorder};
    margin: 20px 15px 0 0;
    cursor: pointer;

    &:hover ${Description} {
        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 2;
    }
`;

export const Img = styled.img`
    width: 100%;
    height: 100%;
`;

export const ImagesWrapper = styled.div`
    display: flex;
    flex-flow: row wrap;
    width: 100%;

    @media ${deviceMax.tabletXl} {
        justify-content: center;
        padding: 0 50px;
        box-sizing: border-box;
    }

    @media ${deviceMax.mobileL} {
        flex-flow: column;
        justify-content: center;
        padding: 0;
        width: 154px;
        margin: 0 auto;
    }
`;

export const Paragraph = styled.p`
    font-family: Arial, Helvetica;
    font-size: 0.875em;
    line-height: 1.5em;
    font-weight: 400;
    max-width: ${containerWidth};
    margin-bottom: 3em;

    @media ${deviceMax.tabletM} {
        margin-left: 1em;
        margin-right: 1em;
    }

    @media ${deviceMax.tablet} {
        margin-left: 0;
        margin-right: 0;
    }
`;

export const Bold = styled.div`
    font-weight: 700;
    font-family: Arial, Helvetica;
    font-size: 0.875em;
    line-height: 1.4em;
`;
