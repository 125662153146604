import React from 'react';
import {
    Button,
    ButtonWrapper,
    CenterBox,
    DecriptionSection,
    Header,
    HeaderTitle,
    List,
    ListItem,
    MainSection,
    Paragraph,
    SectionImage,
    SubHeading,
    Img,
} from './SmallCompressorsTopDescriptionStyle';
import image from '../../../static/images/kleinekompressoren/kleine-kompressoren_boge-c-2_baureihe.png';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const SmallCompressorsTopDescription = () => {
    const { t } = useTranslation();

    const data = [
        t('topListItem1'),
        t('topListItem2'),
        t('topListItem3'),
        t('topListItem4'),
        t('topListItem5'),
        t('topListItem6'),
        t('topListItem7'),
    ];

    const displayData = (data: any) =>
        data.map((item: String, index: number) => (
            <ListItem data-icon="✓" key={index}>
                {item}
            </ListItem>
        ));

    return (
        <>
            <Header>
                <HeaderTitle>Die BOGE C-Baureihe</HeaderTitle>
                <SubHeading>{t('kleineKompressorenFurIndustrie')}</SubHeading>
            </Header>
            <MainSection>
                <CenterBox>
                    <SectionImage>
                        <Img src={image} alt="kleine-kompressoren-baureihe" />
                    </SectionImage>
                    <DecriptionSection>
                        <SubHeading>{t('kleineKompressorenVonBoge')}</SubHeading>
                        <Paragraph>{t('kleineKompressorenVonBogeText')}</Paragraph>
                        <SubHeading>{t('vorteile')}</SubHeading>
                        <List>{displayData(data)}</List>
                        <ButtonWrapper>
                            <Button href="#SmallCompressorsContact">{t('buttonTextTopDesc')}</Button>
                        </ButtonWrapper>
                    </DecriptionSection>
                </CenterBox>
            </MainSection>
        </>
    );
};

export default SmallCompressorsTopDescription;
