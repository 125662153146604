import React, { useState } from 'react';
import { Background, CloseButton, ButtonWrapper } from './GalleryViewStyle';
import Image from './Image';

const GalleryView = ({ closeView, imageId, images }) => {
    const [imageID, setImageID] = useState(imageId);

    const nextImage = (): void => {
        setImageID((imageID: number) => ++imageID);
    };

    const previousImage = (): void => {
        setImageID((imageID: number) => --imageID);
    };

    return (
        <Background>
            <ButtonWrapper>
                <CloseButton onClick={closeView}></CloseButton>
            </ButtonWrapper>
            <Image imageID={imageID} images={images} nextImage={nextImage} previousImage={previousImage}></Image>
        </Background>
    );
};

export default GalleryView;
