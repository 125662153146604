import styled from 'styled-components';
import { IoMdClose } from 'react-icons/io';
import { deviceMax } from '../../styles/MediaQuery';

export const Background = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 3;
    display: flex;
    flex-flow: column nowrap;
    box-sizing: border-box;

    @media ${deviceMax.tablet} {
        display: none;
    }
`;

export const CloseButton = styled(IoMdClose)`
    fill: rgba(255, 255, 255, 0.8);
    font-size: 5em;
    cursor: pointer;

    &:hover {
        fill: white;
    }
`;

export const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 5%;
`;
