import linkDe from '../JSON/KleineKompressorenLinks/kleine-kompressoren-de.json';
import linkEn from '../JSON/KleineKompressorenLinks/kleine-kompressoren-en.json';
import linkEs from '../JSON/KleineKompressorenLinks/kleine-kompressoren-es.json';
import linkFr from '../JSON/KleineKompressorenLinks/kleine-kompressoren-fr.json';
import { KleineKompressorenLinkInterface } from '../interfaces/kleineKompressorenLinks';
import { DE, EN, ES, FR } from '../constants/languageCode';

export const getSmallCompressorLinkInCurrentLanguage = (language: string): Array<KleineKompressorenLinkInterface> => {
    switch (language) {
        case DE:
            return linkDe;
        case EN:
            return linkEn;
        case ES:
            return linkEs;
        case FR:
            return linkFr;
        default:
            return linkDe;
    }
};
