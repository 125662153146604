import styled from 'styled-components';
import { blue, black, white, greyLight, limaBackground, citronBackground } from '../../../styles/Variables';
import { deviceMax } from '../../../styles/MediaQuery';
import { ContainerColumnStart } from '../../../styles/Common';

export const Wrapper = styled(ContainerColumnStart)`
    margin: 2rem auto;

    @media ${deviceMax.tabletM} {
        padding: 0 15px;
        width: calc(100% - 30px);
    }
`;

export const Title = styled.h2`
    color: ${blue};
    font-size: 1.5em;
`;

export const Paragraph = styled.p`
    font-family: Arial, Helvetica;
    color: ${black};
    font-size: 0.875em;
    line-height: 1.4em;
    margin-bottom: 1.25em;
`;

export const Button = styled.button`
    font-family: Arial, Helvetica;
    font-weight: 400;
    cursor: pointer;
    border-radius: 0.3125em;
    border: none;
    color: ${white};
    padding: 1.125em 2em;
    font-size: 0.875em;
    background: linear-gradient(${limaBackground}, ${citronBackground});
`;

export const Bold = styled.span`
    font-weight: 700;
    font-family: Arial, Helvetica;
    font-size: 1em;
`;

export const Container = styled.div`
    background: ${greyLight};
    display: flex;
    justify-content: center;
`;
