import React from 'react';
import SmallCompressorsItem from './SmallCompressorsItem';
import SmallCompressorsWrapper from './SmallCompressorsWrapper';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { SectionTitle, Row } from './SmallCompressorsStyle';
import { GrayBackground } from '../../../styles/Common';
import kompressoren from '../../../static/images/kleinekompressoren/waermerueckgewinnung-frequenzumrichter_boge-kompressoren.jpeg';
import miniKompressoren from '../../../static/images/kleinekompressoren/vibrationsarm-ohne-schalldaemmung_boge-minikompressoren.jpeg';
import industrieKompressoren from '../../../static/images/kleinekompressoren/klein-kompakt-platzsparend_boge-industriekompressoren.jpeg';
import serviceMontage from '../../../static/images/kleinekompressoren/boge-service_montage-und-lieferung2.jpeg';

const SmallCompressorsSection = () => {
    const { t } = useTranslation();

    return (
        <GrayBackground>
            <SmallCompressorsWrapper>
                <SectionTitle>Vorteile im Detail</SectionTitle>
                <Row>
                    <SmallCompressorsItem
                        img={kompressoren}
                        title={t('optionalEquipmentPossibilities')}
                        description={[
                            t('optionalEquipmentPossibilitiesText1'),
                            t('optionalEquipmentPossibilitiesText2'),
                            t('optionalEquipmentPossibilitiesText3'),
                            t('optionalEquipmentPossibilitiesText4'),
                            t('optionalEquipmentPossibilitiesText5'),
                            t('optionalEquipmentPossibilitiesText6'),
                            t('optionalEquipmentPossibilitiesText7'),
                        ]}
                    />
                    <SmallCompressorsItem
                        img={industrieKompressoren}
                        title={t('compactAndReliable')}
                        description={[
                            t('compactAndReliableText1'),
                            t('compactAndReliableText2'),
                            t('compactAndReliableText3'),
                            t('compactAndReliableText4'),
                            t('compactAndReliableText5'),
                            t('compactAndReliableText6'),
                            t('compactAndReliableText7'),
                            t('compactAndReliableText8'),
                            t('compactAndReliableText9'),
                            t('compactAndReliableText10'),
                            t('compactAndReliableText11'),
                        ]}
                    />
                </Row>
                <Row>
                    <SmallCompressorsItem
                        img={miniKompressoren}
                        title={t('quietAndLowVibration')}
                        description={[
                            t('quietAndLowVibrationText1'),
                            t('quietAndLowVibrationText2'),
                            t('quietAndLowVibrationText3'),
                        ]}
                    />
                    <SmallCompressorsItem
                        img={serviceMontage}
                        title={t('assemblyAndDelivery')}
                        description={[
                            t('assemblyAndDeliveryText1'),
                            t('assemblyAndDeliveryText2'),
                            t('assemblyAndDeliveryText3'),
                        ]}
                    />
                </Row>
            </SmallCompressorsWrapper>
        </GrayBackground>
    );
};

export default SmallCompressorsSection;
