import styled from 'styled-components';
import { black, blue } from '../../../styles/Variables';
import { deviceMax } from '../../../styles/MediaQuery';
import { ContainerColumnStart } from '../../../styles/Common';
import { Link } from 'gatsby';

export const Container = styled(ContainerColumnStart)`
    margin: 2rem auto;

    @media ${deviceMax.tabletM} {
        padding: 0 15px;
        width: calc(100% - 30px);
    }
`;

export const Paragraph = styled.p`
    font-family: Arial, Helvetica;
    color: ${black};
    font-size: 0.875em;
    line-height: 1.4em;
    margin: 0;
`;

export const LinkWrapper = styled.div`
    display: flex;
    flex-flow: row wrap;
`;

export const Bold = styled.div`
    font-weight: 700;
    font-family: Arial, Helvetica;
    font-size: 0.875em;
    line-height: 1.4em;
`;

export const BestsellerLink = styled(Link)`
    font-weight: 400;
    font-family: Arial, Helvetica;
    font-size: 0.875em;
    line-height: 1.4em;
    margin-left: 0.5em;
    text-decoration: none;
    color: ${blue};
`;
