import styled from 'styled-components';
import {
    black,
    blue,
    citronBackground,
    containerWidth,
    greyLight,
    limaBackground,
    white,
} from '../../../styles/Variables';
import { deviceMax } from '../../../styles/MediaQuery';

export const Header = styled.header`
    max-width: ${containerWidth};
    margin: auto;
    padding: 2.5em 0;

    * {
        margin: 0;
    }

    @media ${deviceMax.tabletXl} {
        margin: 0 1em;
    }
`;

export const HeaderTitle = styled.h1`
    font-size: 2.5em;
    color: ${blue};

    @media ${deviceMax.tablet} {
        font-size: 2em;
    }
`;

export const SubHeading = styled.h3`
    font-family: 'Helvetica Neue LT W02_57 Cond', Helvetica, Arial;
    font-size: 0.9em;

    @media ${deviceMax.tablet} {
        font-size: 0.825em;
    }
`;

export const MainSection = styled.section`
    background-color: ${greyLight};
    border-bottom: 1px solid ${white};
`;

export const CenterBox = styled.div`
    max-width: ${containerWidth};
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0px 1em;

    @media ${deviceMax.tabletXl} {
        display: flex;
        flex-direction: column;
        margin: 0 1em;
    } ;
`;

export const SectionImage = styled.section`
    margin: -1.75em auto 0 auto;
`;

export const Img = styled.img`
    @media ${deviceMax.tabletXl} {
        width: 100%;
        margin-left: 0.75em;
    }
`;

export const DecriptionSection = styled.section`
    padding: 1.75em 0 2em 0;

    @media ${deviceMax.tablet} {
        padding-top: 0.25em;
    }

    * {
        color: ${black};
    }
`;

export const Paragraph = styled.p`
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.875em;
    line-height: 1.4em;
    font-weight: 300;
    margin: 0;
`;

export const List = styled.ul`
    font-family: Arial, Helvetica, sans-serif;
    columns: 2;
    padding: 0;

    @media ${deviceMax.tabletXl} {
        max-width: 34em;
        margin: 0 0 1em 1em;
    }

    @media ${deviceMax.tabletXl} {
        columns: 1;
    }
`;

export const ListItem = styled.li`
    margin-bottom: 0.35em;
    list-style: none;
    display: inline-block;
    width: 100%;

    &::before {
        content: attr(data-icon);
        padding: 0 10px 0px 0;
        color: ${blue};
        font-size: 1.25em;
    }
`;

export const ButtonWrapper = styled.div`
    margin-top: 2rem;
`;

export const Button = styled.a`
    font-family: Helvetica;
    font-weight: 300;
    cursor: pointer;
    border-radius: 0.3125em;
    border: none;
    color: ${white};
    padding: 1.125em 2em;
    font-size: 0.875em;
    background: linear-gradient(${limaBackground}, ${citronBackground});
    text-decoration: none;
`;
