import styled from 'styled-components';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { BsCircle, BsCircleFill } from 'react-icons/bs';

export const Img = styled.img`
    max-width: calc(100% - 10em);
    max-height: 100%;
`;

export const ImageWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
`;

export const Description = styled.div`
    color: white;
    font-family: Helvetica, Arial;
    padding: 0 10%;
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
`;

export const Title = styled.h3`
    color: white;
    font-family: Helvetica, Arial;
    font-size: 1.5em;
`;

export const ArrowBox = styled.div`
    height: 100%;
    width: 5em;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
        background: rgba(255, 255, 255, 0.2);
    }
`;

export const LeftArrow = styled(IoIosArrowBack)`
    fill: white;
    font-size: 5em;
`;

export const RightArrow = styled(IoIosArrowForward)`
    fill: white;
    font-size: 5em;
`;

export const ArrowBoxReplacer = styled.div`
    height: 100%;
    width: 5em;
`;

export const DotsWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: 5%;
`;

export const DotFill = styled(BsCircleFill)`
    fill: white;
    margin: 5px;
`;

export const Dot = styled(BsCircle)`
    fill: white;
    margin: 5px;
`;
