import React from 'react';
import {
    Img,
    ImageWrapper,
    Description,
    Title,
    Wrapper,
    LeftArrow,
    ArrowBox,
    RightArrow,
    ArrowBoxReplacer,
    DotsWrapper,
    DotFill,
    Dot,
} from './ImageStyle';

const Image = ({ imageID, images, nextImage, previousImage }) => {
    const displayDots = () => images.map((item: any) => (item.id === imageID ? <DotFill /> : <Dot />));

    return (
        <Wrapper>
            <ImageWrapper>
                {imageID > 0 ? (
                    <ArrowBox onClick={previousImage}>
                        <LeftArrow />
                    </ArrowBox>
                ) : (
                    <ArrowBoxReplacer />
                )}
                <Img src={images[imageID].img} alt={images[imageID].alt} />
                {imageID < images.length - 1 ? (
                    <ArrowBox onClick={nextImage}>
                        <RightArrow />
                    </ArrowBox>
                ) : (
                    <ArrowBoxReplacer />
                )}
            </ImageWrapper>
            <Title>{images[imageID].alt}</Title>
            <Description>{images[imageID].desc}</Description>
            <DotsWrapper>{displayDots()}</DotsWrapper>
        </Wrapper>
    );
};

export default Image;
