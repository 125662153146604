import styled from 'styled-components';

export const Image = styled.img`
    position: absolute;
    top: 6em;
    right: 10em;
    width: 14em;
    cursor: pointer;

    @media (max-width: 1790px) {
        right: 2em;
    }

    @media (max-width: 1450px) {
        top: 7em;
        right: 0em;
        width: 11em;
    }

    @media (max-width: 1260px) {
        display: none;
    }

    &:hover {
        filter: drop-shadow(-2px 2px 6px rgba(0, 0, 0, 0.15));
    }
`;

export const Wrapper = styled.div`
    button {
        margin-left: -9999px;
    }

    @media (max-width: 1260px) {
        display: none;
    }
`;
