import React from 'react';
import { Paragraph, Button, Bold, Container, Wrapper } from './SmallCompressorsAdvantagesDescriptionStyles';
import { SectionTitle } from '../../../styles/Common';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const SmallCompressorsAdvantagesDescription = ({ scrollToContact, scrollRef }) => {
    const { t } = useTranslation();

    return (
        <Container ref={scrollRef}>
            <Wrapper>
                <SectionTitle>{t('smallCompressorsTitle')}</SectionTitle>
                <Paragraph>
                    {t('smallCompressorsLight1')}
                    <Bold>{t('smallCompressorsBold1')}</Bold>
                    {t('smallCompressorsLight2')}
                    <Bold>{t('smallCompressorsBold2')}</Bold>
                    {t('smallCompressorsLight3')}
                </Paragraph>
                <Button onClick={scrollToContact}>{t('smallCompressorsButtonText')}</Button>
            </Wrapper>
        </Container>
    );
};

export default SmallCompressorsAdvantagesDescription;
