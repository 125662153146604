export const tableData = [
    {
        typ: 'C 3 L ... C 9 LF*',
        bar: '10 ... 13',
        m3min: '0,204 ... 1,260 ',
        kW: '2,2 ... 7,5',
        mm: '817 x 495 x 520 ***',
        dB: '61',
    },
    {
        typ: 'C 4 ... C 9*',
        bar: '13',
        m3min: '0,219 ... 1,180',
        kW: '3 ... 7,5',
        mm: '480 x 955 x 907 ***',
        dB: '59',
    },
    {
        typ: 'C 10 L ... C 20 L*',
        bar: '10',
        m3min: '1,030 ... 2,250 ',
        kW: '7,5 ... 15',
        mm: '1170 x 600 x 600 ***',
        dB: '60',
    },
    {
        typ: 'C 15 ... C 30**',
        bar: '13',
        m3min: '1,310 ... 3,620 ',
        kW: '11 ... 22  ',
        mm: '722 x 1740 x 1060 ***  ',
        dB: '63',
    },
    {
        id: '4',
        typ: 'C 12-2 L ... C 15-2 L*    ',
        bar: '10',
        m3min: '1,740 ... 2,260 ',
        kW: '11 ... 15  ',
        mm: '1610 x 915 x 1380 ***  ',
        dB: '63',
    },
    {
        typ: 'C 12-2 LF ... C 22-2 LF*  ',
        bar: '13',
        m3min: '0,510 ... 3,730 ',
        kW: '11 ... 22',
        mm: '1610 x 915 x 1380 ***',
        dB: '63',
    },
    {
        typ: 'C 12-2 ... C 22-2*',
        bar: '13',
        m3min: '1,310 ... 3,640',
        kW: '11 ... 22  ',
        mm: '1610 x 915 x 1380 ***',
        dB: '63',
    },
];

export const tableHeadings = [
    'BOGE',
    'Höchstdruck',
    ['Effektive Liefermenge', '50 Hz'],
    'Motorleistung',
    'Maße B × T × H',
    'Schalldruckpegel ab ...',
];

export const tableSubHeadings = ['Typ', 'bar', 'm3', 'kW', 'mm', 'dB(A)'];
